import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },
  name: "recommended-by",
  data() {
    return {
      requiredRule: [v => !!v || "Field is required"],
      isSaving: false,
      recommendedById: null,
      valid: false,
      shopsSearchInput: null,
      recommendedBy: {
        startDate: null,
        endDate: null,
        cityId: null,
        shopIds: [],
        allCities: false,
        type: "",
        sponsorId: null
      },
      shops: [],
      cities: [],
      sponsors: [],
      search: "",
      citiesIsLoading: false,
      shopsIsLoading: false,
      sponsorsIsLoading: false,
      startDateMenu: false,
      endDateMenu: false,
      types: ["RECOMMENDED_BY_OOBLEE", "RECOMMENDED_BY_DELTA"]
    };
  },
  created() {
    this.recommendedById = this.$route.params.recommendedById;
    let title =
      this.recommendedById != null
        ? "Recommended By details"
        : "Insert new recommended by";
    this.$store.dispatch(SET_BREADCRUMB, [{ title: title }]);
    if (this.recommendedById != null) {
      ApiService.get(`api/recommended-shops`, `${this.recommendedById}`)
        .then(response => {
          this.$log.debug("RecommendedBy: ", response.data);
          this.recommendedBy = response.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    }
    this.searchCities();
    this.searchShops();
    this.searchSponsors();
  },
  methods: {
    previousState() {
      this.$router.go(-1);
    },
    removeCity(item) {
      const index = this.recommendedBy.cityId.indexOf(item.id);
      if (index >= 0) this.recommendedBy.cityId.splice(index, 1);
    },
    removeShops(item) {
      const index = this.recommendedBy.shopIds.indexOf(item.id);
      if (index >= 0) this.recommendedBy.shopIds.splice(index, 1);
    },
    searchSponsors() {
      // Items have already been loaded
      if (this.sponsors.length > 0) return;

      // Items have already been requested
      if (this.sponsorsIsLoading) return;

      this.sponsorsIsLoading = true;
      let params = {};
      params["page"] = 1;
      params["size"] = 999;
      ApiService.query("api/blog", {
        params
      })
        .then(res => {
          this.sponsors = res.data.content;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.sponsorsIsLoading = false));
    },
    searchCities() {
      // Items have already been loaded
      if (this.cities.length > 0) return;

      // Items have already been requested
      if (this.citiesIsLoading) return;

      this.citiesIsLoading = true;
      ApiService.query("api/cities")
        .then(res => {
          this.cities = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.citiesIsLoading = false));
    },
    searchShops() {
      // Items have already been loaded
      if (this.shops.length > 0) return;

      // Items have already been requested
      if (this.shopsIsLoading) return;

      this.shopsIsLoading = true;
      ApiService.query("api/shops/dropdown-list")
        .then(res => {
          this.shops = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.shopsIsLoading = false));
    },
    validateAndSave() {
      if (this.$refs.form.validate()) {
        this.saveRecommendedBy(this.recommendedBy);
      }
    },
    saveRecommendedBy(recommendedBy) {
      this.loading = true;
      if (recommendedBy.id != null) {
        ApiService.put(`api/recommended-shops`, recommendedBy)
          .then(response => {
            this.$log.debug("Recommended By changed: " + response);
            this.$emit("recommendedBySaved", "recommendedBySaved");
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.previousState();
          });
      } else {
        ApiService.post(`api/recommended-shops`, recommendedBy)
          .then(response => {
            this.$log.debug("Recommended By created: " + response);
            this.$emit("recommendedBySaved", "recommendedBySaved");
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.previousState();
          });
      }
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    ...mapGetters(["currentUser"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("input", value);
        }
      }
    }
  }
};
